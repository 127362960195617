import React, { Component } from 'react'
import LayoutMain from '../components/layouts/LayoutMain'
import Image from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const getSuccessImage = graphql`
	query getSuccessPhoto {
		file(relativePath: { eq: "team-high-five.jpg" }) {
			childImageSharp {
				fixed(width: 800) {
					...GatsbyImageSharpFixed_withWebp
				}
			}
		}
	}
`

const Success = () => {
	const data = useStaticQuery(getSuccessImage)
	return (
		<LayoutMain isSuccess={true}>
			<div className="img-container motivational-container">
				<Image className="image-frame" fixed={data.file.childImageSharp.fixed} />
				<div className="image-frame__top-text">
					<h2>SUCCESS</h2>
				</div>
				<div className="image-frame__bottom-text">
					<h3>Your message has been recieved. I will contact you as soon as possible.</h3>
				</div>
			</div>
		</LayoutMain>
	)
}

export default Success

// export class success extends Component {
// 	render() {
// 		return (

// 		)
// 	}
// }

// export default success
